import React from 'react';
import styled from 'styled-components';
import { Container} from 'reactstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Importando as 30 imagens da pasta assets
const images = Array.from({ length: 30 }, (_, index) => require(`../../assets/img/agroflorestry/random (${index + 1}).jpg`));

const Section = styled(Container)`
  background-color: #ffffff; // Fundo escuro para combinar com fotos em preto e branco
  padding: 0 0;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    width: 700px; // Define um tamanho máximo para as imagens
    height: 400px; // Define um tamanho máximo para as imagens
    object-fit: cover;
    margin-right: 0;
    margin-left: 0;
  }

  @media (max-width: 880px) {
    img {
      width: 400px; // Reduz o tamanho máximo para tablets
      height: 150px; // Reduz o tamanho máximo para tablets
    }
  }

  @media (max-width: 480px) {
    img {
      width: 260px; // Reduz o tamanho máximo para telefones
      height: 120px; // Reduz o tamanho máximo para telefones
    }
  }
`;

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 2200,
  cssEase: 'ease-out',
  responsive: [
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const ImageAgroflorestry = () => {
  return (
    <Section fluid>
          <Slider {...sliderSettings}>
            {images.map((src, index) => (
              <ImageWrapper key={index}>
                <img src={src} alt={`Imagem ${index + 1}`} className="img-fluid" />
              </ImageWrapper>
            ))}
          </Slider>
    </Section>
  );
};

export default ImageAgroflorestry;