import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button } from 'reactstrap';
import image1 from '../../assets/img/background/background1.jpg';
import image2 from '../../assets/img/background/background2.jpg';
import image3 from '../../assets/img/background/background3.jpg';
import image4 from '../../assets/img/background/background4.jpg';

const images = [image1, image2, image3, image4];

const Banner = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [cardBodyStyle, setCardBodyStyle] = useState({
    paddingLeft: '1rem',
    paddingRight: '1rem',
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const textStyle = {
    color: 'white',
    textShadow: '0px 0px 8px rgba(0, 0, 0, 1)',
    fontFamily: 'sans-serif',
  };

  const backgroundStyle = {
    backgroundImage: `url(${images[currentImageIndex]})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    position: 'relative',
  };

  const overlayStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };

  useEffect(() => {
    const updatePadding = () => {
      const width = window.innerWidth;
      let paddingLeft, paddingRight;

      if (width < 576) {
        paddingLeft = '1rem';
        paddingRight = '1rem';
      } else if (width >= 768 && width < 992) {
        paddingLeft = '4rem';
        paddingRight = '4rem';
      } else if (width >= 992 && width < 1200) {
        paddingLeft = '9rem';
        paddingRight = '9rem';
      } else if (width >= 1200 && width < 1700) {
        paddingLeft = '12rem';
        paddingRight = '12rem';
      } else if (width >= 1700) {
        paddingLeft = '30rem';
        paddingRight = '30rem';
      }

      setCardBodyStyle({ paddingLeft, paddingRight });
    };

    updatePadding();
    window.addEventListener('resize', updatePadding);

    return () => window.removeEventListener('resize', updatePadding);
  }, []);

  return (
    <Card className="card-lift shadow border-0" style={{ minHeight: '20.125rem' }}>
      <div className="card-background" style={backgroundStyle}>
        <div style={overlayStyle}></div>
        <CardBody className="py-5 d-flex flex-column justify-content-center align-items-center text-center" style={{ ...cardBodyStyle, minHeight: '20.125rem', position: 'relative' }}>
          <h1 style={{ ...textStyle, fontSize: '3.5rem' }}>Reflora, sua plataforma de sistemas agroflorestais</h1>
          <p style={{ ...textStyle, fontSize: '2rem', marginTop: '50px' }}>Auxiliamos tomadores de decisão no planejamento de sistemas agrícolas regenerativos e sustentáveis.</p>
          <div className="d-flex flex-wrap justify-content-center mt-3">
            <Link to="/auth/begin">
              <Button className="btn-icon btn-3" style={{ backgroundColor: '#155F2B', color: 'white', border: '0', fontSize: '1rem', padding: '10px 20px', marginRight: '10px' }} type="button">
                Demonstração
              </Button>
            </Link>
          </div>
        </CardBody>
      </div>
    </Card>
  );
};

export default Banner;