import React from "react";
import styled from "styled-components";
import MarieImage from "../../assets/img/marie.png";
import VilmarImage from "../../assets/img/vilmar.png";
import DanielImage from "../../assets/img/daniel.png";
import GayatriImage from "../../assets/img/gayatri.png";

const StyledContainer = styled.div`
  display: flex;
  padding: 0rem 0rem;
  margin: 0;
  flex-direction: column;
  overflow: hidden;
`;

const Main = styled.main`
  flex-grow: 1;
`;

const Section = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 1rem;
`;

const PageHeader = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 3rem;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0;
    color: black;
  }
`;

const TeamGrid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  justify-items: center;
`;

const TeamMember = styled.div`
  max-width: 600px;
  margin-top: 2rem;
  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
  background-color: #ffffff;
  text-align: center;
  padding: 2rem;

  img {
    border-radius: 50%;
    margin-top: -4rem;
    width: 96px;
    height: 96px;
    object-fit: cover;
  }

  blockquote {
    font-size: 1rem;
    font-weight: 500;
    margin: 1rem 0;
  }

  cite {
    display: block;
    font-size: 1.125rem;
    font-weight: 700;
  }

  div {
    color: #4a5568;
    font-size: 0.875rem;

    a {
      color: #38a169;
      text-decoration: underline;
    }
  }
`;

const AboutUs = () => {

  return (
    <StyledContainer id='nos'>
      <Main>
        <Section>
          <PageHeader>
            <h1>Olá! Conheça mais sobre nossa equipe</h1>
          </PageHeader>
          <TeamGrid>
            <TeamMember>
              <img src={MarieImage} alt="Imagem Marie" />
              <blockquote>
                Mais de 8 anos pesquisando restauração ecológica e sistemas
                agroflorestais em regiões de Caatinga, Cerrado, Mata Atlântica e
                Amazônia.
              </blockquote>
              <cite>Marie Hamelak</cite>
              <div>
                <span>CEO/Agrofloresta</span>{" "}
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/in/maria-clara-silva-hamelak-162189238/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @Marie
                </a>
              </div>
            </TeamMember>
            <TeamMember>
              <img src={VilmarImage} alt="Imagem Vilmar" />
              <blockquote>
                Profissional com 25 anos de agrofloresta. Trabalhou com sistemas
                agroflorestais na região do Sahel africano.
              </blockquote>
              <cite>Vilmar Lermen</cite>
              <div>
                <span>Agrofloresta</span>{" "}
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/in/vilmar-lermen-3b764273/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @Vilmar
                </a>
              </div>
            </TeamMember>
            <TeamMember>
              <img src={DanielImage} alt="Imagem Daniel" />
              <blockquote>
                Engenheiro com experiência na arquitetura e desenvolvimento de
                software.
              </blockquote>
              <cite>Daniel Bento</cite>
              <div>
                <span>Arquiteto de Sistemas</span>{" "}
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/in/danbcs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @Bento
                </a>
              </div>
            </TeamMember>
            <TeamMember>
              <img src={GayatriImage} alt="Imagem Gayatri" />
              <blockquote>
                Formada em direito, atua com gestão de projetos sociais há 6
                anos. Trabalha com terapias integrativas e mulheres.
              </blockquote>
              <cite>Gayatri</cite>
              <div>
                <span>Gestão/Jurídico</span>{" "}
              </div>
              <div>
                <a
                  href="https://www.instagram.com/gayatridevi__/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @Gayatri
                </a>
              </div>
            </TeamMember>
          </TeamGrid>
        </Section>
      </Main>
    </StyledContainer>
  );
};

export default AboutUs;
