import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import cnpqLogo from '../../assets/img/partners/cnpq.png';
import impactosPositivosLogo from '../../assets/img/partners/impactospositivos.svg';
import bidaoCuboLogo from '../../assets/img/partners/bidaocubo.png';
import climateVenturesLogo from '../../assets/img/partners/climateventures.png';
import empreendeAmazoniaLogo from '../../assets/img/partners/empreendeamazonia.png';
import sinapsebioLogo from '../../assets/img/partners/sinapsebio.svg';

const sponsors = [
  { src: cnpqLogo, alt: "CNPq Logo" },
  { src: impactosPositivosLogo, alt: "Impactos Positivos Logo" },
  { src: bidaoCuboLogo, alt: "Bidão Cubo Logo" },
  { src: climateVenturesLogo, alt: "Climate Ventures Logo" },
  { src: empreendeAmazoniaLogo, alt: "Empreende Amazônia Logo" },
  { src: sinapsebioLogo, alt: "Sinapse Bio Logo" },
];

const sectionStyle = {
  backgroundColor: '#7A957E',
  padding: '3rem 0',
};

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'ease-out',
  arrows: false,
  centerMode: true,
  centerPadding: '60px',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: '20px',
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: '10px',
      },
    },
  ],
};

const imageStyle = {
  width: 'auto',
  height: '90px',
  display: 'block',
  margin: '0 auto'
};

const Sponsors = () => (
  <Container fluid style={sectionStyle} id='parceiros'>
    <Row>
      <Col className="text-center">
        <h2 style={{ fontSize: '3rem', color: 'black', fontFamily: 'sans-serif' }}>Apoiadores</h2>
      </Col>
    </Row>
    <Row className="justify-content-center my-4">
      <Col className="justify-content-center my-4">
        <Slider {...sliderSettings}>
          {sponsors.map((sponsor, index) => (
            <div key={index}>
              <img
                src={sponsor.src}
                alt={sponsor.alt}
                className="img-fluid"
                style={imageStyle}
              />
            </div>
          ))}
        </Slider>
      </Col>
    </Row>
  </Container>
);

export default Sponsors;