import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import data from '../../safs.json';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

const BeginV2 = () => {
  const biomasJson = data.Biomas;
  const history = useHistory();
  const [selectedBioma, setSelectedBioma] = useState(null);
  const [selectedTipoSAF, setSelectedTipoSAF] = useState(null);
  const [carrochefe, setCarrochefe] = useState([]);
  const [speciesSaf, setSpeciesSaf] = useState([]);
  const [image, setImage] = useState("");
  const [comprimentoCanteiro, setComprimentoCanteiro] = useState(0);
  const [distanciaCanteiro, setDistanciaCanteiro] = useState(0);
  const [tipoSaf, setTipoSaf] = useState([]);
  const [safs, setSafs] = useState([]);
  const [biomas, setBiomas] = useState([]);
  const [specieChefe, setSpecieChefe] = useState([]);
  const [modal, setModal] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [especieSubstituta, setEspecieSubstituta] = useState(["Feijão", "Mandioca"]);
  const [selected, setSelected] = useState([]);

  const listarBiomas = (jsonData) => {
    return jsonData.map(bioma => ({ id: bioma.Id, nome: bioma.Bioma, Tipo_SAF: bioma.Tipo_SAF }));
  };

  const handleBiomaChange = (event) => {
    const selected = event.target.value;
    setSelectedBioma(selected);
    setSelectedTipoSAF(null);
    const tiposSafs = listarTiposSAF(biomasJson, selected);
    setTipoSaf(tiposSafs);
  };

  const handleTipoSAFChange = (event) => {
    setSelectedTipoSAF(event.target.value);
    const selectedBiomaData = biomasJson.find(b => b.Bioma === selectedBioma);
    if (selectedBiomaData) {
      const tipoSAFData = selectedBiomaData.Tipo_SAF[event.target.value];
      if (tipoSAFData) {
        setImage(tipoSAFData.image || "");
        setComprimentoCanteiro(tipoSAFData.comprimentoCanteiro || 0);
        setDistanciaCanteiro(tipoSAFData.distanciaCanteiro || 0);
        setSpeciesSaf(tipoSAFData.Especies || []);
        setSelected(tipoSAFData.Especies || []);
        const  carroChefeTest =  Array.isArray(tipoSAFData["Carro-chefe"]) ? tipoSAFData["Carro-chefe"] : [tipoSAFData["Carro-chefe"]];
        setCarrochefe(carroChefeTest);
      }
    }
  };

  const listarTiposSAF = (jsonData, biomaName) => {
    const bioma = jsonData.find(b => b.Bioma === biomaName);
    if (!bioma) return [];
  
    return Object.entries(bioma.Tipo_SAF).map(([tipo, detalhes]) => ({
      tipo,
      ...detalhes
    }));
  };






  useEffect(() => {
    setBiomas(listarBiomas(biomasJson));
    axios.get("https://reflora.app.br:5001/api/v1/typesaf/safs")
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const data = response.data[0];
          setSafs(response.data);
          setImage(data.image || "");
          setComprimentoCanteiro(data.comprimentoCanteiro || 0);
          setDistanciaCanteiro(data.distanciaCanteiro || 0);
        }
      }).catch((error) => {
        console.error(error);
      });
  }, []);

  const handleChange = (e) => {
    if(e.target.options.selectedIndex == 0) {
      setSpecieChefe([]);
    } else {
      const selectedIndex = e.target.options.selectedIndex;
      const selectedOption = e.target.options[selectedIndex];
      const Id = Number(selectedOption.getAttribute('data'));
      const Nome = e.target.value;
      setSpecieChefe([{Id, Nome }]);
    }
  };

  const removeItem = (item) => {
    setSelected(selected.filter(s => s !== item));
    setItemToRemove(item)
    toggleModal();
  };

  const removeCarrochefe = (item) => {
    setSpecieChefe(specieChefe.filter(s => s !== item));
  }

  const toggleModal = () => setModal(!modal);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const uniqueCode = uuidv4();
    const saf = {
      codigo: uniqueCode,
      largura: parseFloat(e.target["largura"].value),
      comprimento: parseFloat(e.target["comprimento"].value),
      user_id: 1,
    };
    const  speciesIDs = selected.map(item => item.Id).filter(Nome => !isNaN(Nome)).concat(specieChefe.map(item => item.Id).filter(Nome => !isNaN(Nome)));
    const formData = {
      safCode: uniqueCode,
      speciesIDs,
      comprimento: parseFloat(e.target["comprimento"].value) || 0,
      largura: parseFloat(e.target["largura"].value) || 0,
      comprimentoCanteiro,
      distanciaCanteiro
    };

    axios.post("https://reflora.app.br:5001/api/v1/saf", saf)
      .then((response) => {
        if (response.status === 201) {
          axios.post("https://reflora.app.br:5001/api/v1/production", formData)
            .then((response) => {
              if (response.status === 201) {
                history.push(`/admin/index/${uniqueCode}`);
              } else {
                console.log("Erro na resposta do POST.");
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.log("Erro na resposta do POST.");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardHeader className="bg-transparent pb-4">
          <div className="text-muted text-center mt-2 mb-3">
            <h2>Inicie aqui</h2>
          </div>
          <Form role="form">
            <FormGroup className="mb-1">
              <label className="form-control-label" htmlFor="bioma-safs">
                Selecione o bioma para o sistema agroflorestal
              </label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-collection" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Amazônia"
                  type="select"
                  id="bioma-safs"
                  onChange={handleBiomaChange}
                >
                <option key="0" value="0" >Selecione...</option>
                  {biomas.map((biomas) => 
                    biomas ? (
                    <option key={biomas.id} value={biomas.nome}>
                      {biomas.nome}
                    </option>
                  ) : null
                  )}
                </Input>
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-1">
              <label className="form-control-label mt-4 mb-3" htmlFor="tipos-safs">
                Selecione o tipo de sistema agroflorestal
              </label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-collection" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Horta"
                  type="select"
                  id="tipos-safs"
                  onChange={handleTipoSAFChange}
                >
                 <option key="0" value="0" >Selecione...</option>
                          {tipoSaf.map((tipoSaf, index) =>
                            tipoSaf ? (
                              <option key={index} value={tipoSaf.tipo}>
                                {tipoSaf.tipo}
                              </option>
                            ) : null
                          )}
                </Input>
              </InputGroup>
              <div className="safs-image">
                {image ? (
                  <img
                    alt={`${image}`}
                    key={image}
                    src={process.env.PUBLIC_URL + image}
                  />
                ) : (<p>Carregando...</p>)}
              </div>
            </FormGroup>
          </Form>
        </CardHeader>
        <CardBody className="px-lg-3 py-lg-4">
          <Form role="form" onSubmit={handleSubmit}>
            <div className="pl-lg-0 py-lg-2">
            <FormGroup className="mb-1">
              <label className="form-control-label" htmlFor="bioma-safs">
                Selecione o carro chefe
              </label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-collection" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Selecione uma espécie"
                  type="select"
                  id="carrochefe"
                  onChange={handleChange}
                >
                <option key="0" id="0" value="0" >Selecione...</option>
                          {carrochefe.map((chefe) =>
                            chefe.Nome ? (
                              <option key={chefe.Id} data={chefe.Id} value= {chefe.Nome}>
                                {chefe.Nome}
                              </option>
                            ) : null
                          )}
                </Input>
              </InputGroup>
            </FormGroup>
            </div>
            <div>
              <strong>Espécies selecionadas:</strong>
              <div>
                {selected.map(item => (
                  <Badge className="mt-1"
                    key={item?.Id}
                    color="primary"
                    onClick={() => removeItem(item)}
                    style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer', marginRight: '9px' }}
                  >
                    {item?.Nome} <Button close size="sm" />
                  </Badge>
                ))}
                {specieChefe.map(item => (
                  <Badge className="mt-1"
                    key={item?.Id}
                    color="primary"
                    onClick={() => removeCarrochefe(item)}
                    style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer', marginRight: '9px' }}
                  >
                    {item?.Nome} <Button close size="sm" />
                  </Badge>
                ))}
              </div>
            </div>
            <FormGroup className="pl-lg-0 py-lg-4">
              <Row>
                <Col lg="6">
                  <label
                    className="form-control-label"
                    title="Comprimento do espaço para plantação"
                    htmlFor="text-area"
                  >
                    Comprimento (m)
                  </label>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-bold-up" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input id="comprimento" placeholder="Comprimento" type="number" />
                  </InputGroup>
                </Col>
                <Col lg="6">
                  <label
                    className="form-control-label"
                    title="Largura do espaço para plantação"
                    htmlFor="largura"
                  >
                    Largura (m)
                  </label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-bold-right" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input id="largura" placeholder="Largura" type="number" />
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup>
            <div className="text-center">
              <Button className="my-4" color="primary" type="submit">
                GERAR INFORMAÇÕES
              </Button>
            </div>
            <div className="text-center text-muted mb-4">
              <small>
                Alguma dúvida? <b>Clique aqui</b>
              </small>
            </div>
          </Form>
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader >Confirmar Substituição</ModalHeader>
            <ModalBody>
              Escolha a espécie que deseja substituir pela espécie {itemToRemove?.label}?
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>{especieSubstituta[0]}</Button>
              <Button color="secondary" onClick={toggleModal}>{especieSubstituta[1]}</Button>
            </ModalFooter>
          </Modal>
        </CardBody>
      </Card>
    </Col>
    
  );
};

export default BeginV2;